import React from 'react';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import AuthHeader from 'components/Headers/AuthHeader.js';
import { forgotPassword } from 'services/authService';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { errorToast, successToast } from 'shared/constants';
import SpinnerLoader from 'components/Misc/Spinner';

import './Auth.css';

function ForgotPasswordInstructions() {
  const history = useHistory();

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [showSpinner, setSpinner] = React.useState(false);

  const handleSubmit = async () => {
    setSpinner(true);
    forgotPassword(email)
      .then((data) => {
        data.success
          ? toast.success(
              'A rest link has been sent to your associated email. Click to reset your password.',
              successToast
            )
          : toast.error('User not Registered', errorToast);
        setSpinner(false);
      })
      .catch(() => {
        toast.error('Something went wrong', errorToast);
        setSpinner(false);
      });
  };

  const keyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <AuthHeader />
      <div className='loginForm instructions-container'>
        <Container className='mt-n18'>
          <Row className='justify-content-center'>
            <Col lg='5' md='7'>
              <Card className='bg-default-login border-0 mx-4 mb-0 py-4 px-3'>
                <CardBody className='px-lg-2 py-lg-3'>
                  <div className='d-flex justify-content-center my-5'>
                    <img
                      src={'/gym-app-logo.png'}
                      className='bt_logo'
                      alt='logo'
                    />
                  </div>
                  <div className='horizontal-ruler'></div>
                  <div className='text-center text-muted'>
                    <p className='auth-title instructions-title'>
                      Reset Password
                    </p>
                  </div>
                  <div className='instructions'>
                    <p>
                      If an account exists for "Farhan@gmail.com" we'll send
                      instructions for resetting your password
                    </p>
                    <p>
                      Didn't get them? Please check your spam or junk folder,
                      and if it's not there, click on the 'Resend' button to
                      send it again to your email address
                    </p>
                  </div>
                  <div className='forgotpassword-footer'>
                    <div className='text-center'>
                      <Button
                        className='default-button-background w-50 mt-1 py-1 login-button'
                        color='info'
                        type='button'
                        onClick={(e) => history.push('/auth/login')}
                      >
                        <h3 className='mb-0 text-white py-1'>Back To Signin</h3>
                      </Button>
                    </div>
                    <div className='d-flex justify-content-center mt-3 back-to-login'>
                      <p>Don't receive the reset Instruction?</p>
                      <p
                        className='back-login px-2 mb-0 signin-text'
                        role={'button'}
                      >
                        Resend
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ForgotPasswordInstructions;
