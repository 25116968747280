import {
  postCall,
  authorizedPostCall,
  authorizedPutCall,
  authorizedDeleteCall,
  authorizedGetCall,
} from './APIsService';
import jwtDecode from 'jwt-decode';

export const isLoggedIn = () => {
  const currentDate = new Date();
  const token = localStorage.getItem('access_token');
  if (!token) {
    return false;
  }
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    localStorage.removeItem('access_token');
    return false;
  }
  return true;
};

export const login = async (email, password) => {
  return new Promise((resolve, reject) => {
    postCall('/user/login', { email, password })
      .then((data) => {
        localStorage.setItem('access_token', data.accessToken);
        localStorage.setItem('tenant_id', data.user.tenantId);
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgotPassword = (email) => {
  return new Promise((resolve, reject) => {
    postCall('/user/forgot_password', { email })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetPassword = (password, confirmPassword, token) => {
  return new Promise((resolve, reject) => {
    postCall(`/user/reset_password/${token}`, {
      password,
      confirmPassword,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const validateResetLink = (token) => {
  console.log('validate reset link');
  return new Promise((resolve, reject) => {
    postCall(`/user/validate_reset_link/${token}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changePassword = (oldPassword, password, confirmPassword) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall('/user/change_password', {
      oldPassword,
      password,
      confirmPassword,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateUser = (userId, body) => {
  return new Promise((resolve, reject) => {
    authorizedPutCall(`/user/update/${userId}`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resendCredentials = (userId) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/user/resend/credentials/${userId}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addUser = (body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/user/add`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteUser = (id) => {
  return new Promise((resolve, reject) => {
    authorizedDeleteCall(`/user/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getOneUser = (id) => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/user/${id}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProfile = () => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(`/user`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deletePaymentHistory = (userId, historyId) => {
  return new Promise((resolve, reject) => {
    authorizedDeleteCall(`/user/${userId}/payment/history/${historyId}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addPaymentHistory = (body, userId) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/user/${userId}/payment/history`, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
