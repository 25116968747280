import React, { useCallback, useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Input,
} from 'reactstrap';
// core components
import SpinnerLoader from 'components/Misc/Spinner';
import toast from 'react-hot-toast';
import moment from 'moment';
import _debounce from 'lodash/debounce';
import DatePickerComp from 'components/DateRangePicker/DatePicker';
import { changeStatus } from 'services/attendanceService';
import Papa from 'papaparse';
import { downloadData } from 'services/utilService';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { pendingRequestStatusFilter } from 'shared/constants';
import { MultiDropdown } from 'components/Misc/MultiDropdown';
import { roleFilter } from 'shared/constants';
import { ROLES } from 'shared/constants';
import { handleError } from 'services/utilService';
import { successToast } from 'shared/constants';
import { getRole } from 'services/utilService';
import { getPendingRequests } from 'services/pendingRequestService';
import { acceptOrRejectRequest } from 'services/pendingRequestService';
import { removeInvitationRequest } from 'services/pendingRequestService';
import { USER_STATUS } from 'shared/constants';

function PendingRequests() {
  const history = useHistory();

  const pageSize = 10;
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showSpinner, setSpinner] = useState(true);
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState([]);
  const [role, setRole] = useState([]);
  const [shownRow, setShownRow] = useState();

  React.useEffect(() => {
    fetchPendingRequests({ date });
  }, [date]);

  const fetchPendingRequests = async (body = null) => {
    console.log('fetchinnnng');

    setSpinner(true);
    getPendingRequests(body)
      .then((data) => {
        setUsers(data.data.users);
        setCount(data.meta.total_count);
        setSpinner(false);
      })
      .catch((error) => {
        setSpinner(false);
        handleError(error);
      });
  };

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
      pagesArr.push(
        <PaginationItem className={currentPage === i ? 'active' : ''}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };
  console.log('data->', users);

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchPendingRequests({
      size: pageSize,
      pageNo: pageNumber,
      ...(search.trim() && { search: search.trim() }),
      date,
    });
    setPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value);
  };

  function debounceSearch(val, statusVal = status, roleVal = role) {
    console.log({ statusVal, roleVal });
    fetchPendingRequests({
      size: pageSize,
      pageNo: 1,
      ...(val.trim() && { search: val.trim() }),
      ...(statusVal && statusVal.length && { status: statusVal }),
      ...(roleVal && roleVal.length && { role: roleVal }),
    });
  }

  const debounceFn = useCallback(_debounce(debounceSearch, 500), []);

  const changeAttendanceStatus = (value) => {
    console.log('change status', value);
    setStatus(value);
    debounceFn(search, value);
  };

  const changeRole = (value) => {
    console.log('change role', value);
    setRole(value);
    debounceFn(search, status, value);
  };

  const changeStatusHandler = async (e, user, role) => {
    try {
      setSpinner(true);
      await changeStatus({ userId: user._id, date: date.toISOString(), role });
      fetchPendingRequests({
        size: pageSize,
        pageNo: currentPage,
        ...(search.trim() && { search: search.trim() }),
        date,
      });
      toast.success('Status Changed!', successToast);
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const handleAccepOrReject = async (userId, isAccepted) => {
    console.log('handle accpt reject');
    try {
      setSpinner(true);
      await acceptOrRejectRequest(userId, { isAccepted });
      toast.success(
        `Request ${isAccepted ? 'accepted' : 'rejected'}!`,
        successToast
      );
      setSpinner(false);
      fetchPendingRequests();
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const handleDeletePendingRequest = async (userId) => {
    try {
      setSpinner(true);
      await removeInvitationRequest(userId);
      toast.success('Invitation request revoked!', successToast);
      setSpinner(false);
      fetchPendingRequests();
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container className='mt-3' fluid>
        <div className='mb-3 d-flex align-items-center justify-content-end'>
          <div className='search-input mr-3'>
            <div className='search-icon'>
              <img src='/search-icon.svg' />
            </div>
            <Input
              className='searchBox'
              placeholder='Search'
              type='text'
              value={search}
              onChange={handleSearch}
            />
          </div>
          <div className='date-picker'>
            <DatePickerComp
              date={date}
              setDate={setDate}
              maxDate={moment().toDate()}
            />
          </div>
        </div>
        <Row>
          <div className='col'>
            <Card>
              <CardHeader className='border-0'>
                <div className='table-header'>
                  <div className='header-left'>
                    <div className='table-title'>Pending Requests</div>
                  </div>
                  <div className='header-right'>
                    <div className='header-right'>
                      <div className='mr-2'>
                        <MultiDropdown
                          placeholder={'Status'}
                          value={status}
                          options={pendingRequestStatusFilter}
                          valueChange={changeAttendanceStatus}
                        />
                      </div>
                    </div>
                    <div>
                      <MultiDropdown
                        placeholder={'User Type'}
                        value={role}
                        options={roleFilter}
                        valueChange={changeRole}
                      />
                    </div>
                  </div>
                </div>
              </CardHeader>
              <div className='table-responsive'>
                <Table className='dataTable align-items-center'>
                  <thead className='thead-bh icon-color-light'>
                    <tr>
                      <th className='w-30' scope='col'>
                        EMAIL/PHONE
                      </th>
                      <th className='w-20' scope='col'>
                        STATUS
                      </th>
                      <th className='w-20' scope='col'>
                        REQUEST TYPE
                      </th>
                      <th className='w-20' scope='col'>
                        USER TYPE
                      </th>
                      <th className='10' scope='col'></th>
                    </tr>
                  </thead>
                  <tbody className='list'>
                    {users.length ? (
                      users.map((user, index) => (
                        <>
                          <tr key={index}>
                            <td className='overflowStyle pl-0 pr-0 present'>
                              <span>{user?.email || user?.phone}</span>
                              {user?.hybridRole && (
                                <span>
                                  <img
                                    src='/hybrid-role.svg'
                                    className='small-icon'
                                  />
                                </span>
                              )}
                            </td>
                            <td className='overflowStyle text-capitalize pl-0 pr-0'>
                              {user.status}
                            </td>
                            <td className='overflowStyle text-capitalize pl-0 pr-0'>
                              {user?.requestType || ''}
                            </td>

                            <td className='overflowStyle text-capitalize pl-0 pr-0 present'>
                              {user.role || ''}
                            </td>
                            <td className='actionDropdown px-0'>
                              {getRole() !== ROLES.frontdesk && (
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className='btn-icon-only text-light action-bg'
                                    color=''
                                    role='button'
                                    size='sm'
                                  >
                                    <img src='/action.svg' />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className='dropdown-menu-arrow'
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) =>
                                        handleAccepOrReject(user._id, true)
                                      }
                                      disabled={
                                        user.status === USER_STATUS.pending
                                          ? false
                                          : true
                                      }
                                    >
                                      <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                                        <div>Accept</div>
                                      </div>
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(e) =>
                                        handleAccepOrReject(user._id, false)
                                      }
                                      disabled={
                                        user.status === USER_STATUS.pending
                                          ? false
                                          : true
                                      }
                                    >
                                      <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                                        <div>Reject</div>
                                      </div>
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={(e) =>
                                        handleDeletePendingRequest(user._id)
                                      }
                                      disabled={
                                        user.status === USER_STATUS.invited &&
                                        !user.hybridRole
                                          ? false
                                          : true
                                      }
                                    >
                                      <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                                        <div>Revoke Invitation</div>
                                      </div>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              )}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <></>
                    )}
                    <tr></tr>
                  </tbody>
                </Table>
                {!showSpinner && !users.length && (
                  <div className='d-flex align-items-center justify-content-center mb-3'>
                    No records found
                  </div>
                )}
              </div>
              <CardFooter className='py-4 custom-footer'>
                <nav
                  className='d-flex align-items-center justify-content-end'
                  aria-label='...'
                >
                  <Pagination
                    className='pagination justify-content-end mb-0'
                    listClassName='justify-content-end mb-0'
                  >
                    <PaginationItem
                      className={currentPage === 1 ? 'disabled' : ''}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage - 1)}
                        tabIndex='-1'
                      >
                        <i className='fas fa-angle-left icon-color-light' />
                        <span className='sr-only'>Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {pages().map((page, index) => (
                      <div key={'page-' + index}>{page}</div>
                    ))}
                    <PaginationItem
                      className={
                        currentPage >= Math.ceil(count / pageSize)
                          ? 'disabled'
                          : ''
                      }
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage + 1)}
                      >
                        <i className='fas fa-angle-right icon-color-light' />
                        <span className='sr-only'>Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default PendingRequests;
