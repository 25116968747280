export const updateToast = {
  className: 'updateToast',
  iconTheme: {
    primary: 'white',
    secondary: '#3a3a73',
  },
};

export const successToast = {
  className: 'successToast',
  iconTheme: {
    primary: 'white',
    secondary: '#76d89c',
  },
};

export const errorToast = {
  className: 'errorToast',
  iconTheme: {
    primary: 'white',
    secondary: 'red',
  },
};

export const ROLES = {
  admin: 'ADMIN',
  trainee: 'TRAINEE',
  trainer: 'TRAINER',
  frontdesk: 'FRONTDESK',
};

export const genderFilters = [
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' },
  { value: 'OTHER', label: 'Other' },
];

export const statusFilters = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' },
];

export const paymentMethodFilter = [
  { value: 'CASH', label: 'Cash' },
  { value: 'RECEIPT', label: 'Receipt' },
  { value: 'OTHER', label: 'Other' },
];

export const attendanceStatusFilters = [
  { value: true, label: 'Present' },
  { value: false, label: 'Absent' },
];

export const pendingRequestStatusFilter = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'REJECTED', label: 'Rejected' },
];

export const roleFilter = [
  { value: 'TRAINER', label: 'Trainer' },
  { value: 'TRAINEE', label: 'Trainee' },
  {
    value: 'hybridRole',
    label: (
      <>
        <img src='/hybrid-role.svg' className='small-icon' />
      </>
    ),
  },
];

export const membershipFilters = [
  { value: 'VIP(Family)', label: 'VIP(Family)' },
  { value: 'General(Family)', label: 'General(Family)' },
  { value: 'VIP(Single)', label: 'VIP(Single)' },
  { value: 'General(Single)', label: 'General(Single)' },
];

export const THERAPY_STATUS = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
};

export const USER_STATUS = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  rejected: 'REJECTED',
  invited: 'INVITED',
  pending: 'PENDING',
};

export const MEMBERSHIPS = {
  vip_family: 'VIP(Family)',
  general_family: 'General(Family)',
  vip_single: 'VIP(Single)',
  general_single: 'General(Single)',
};

export const MEMBERSHIPS_ENUM = [
  MEMBERSHIPS.general_family,
  MEMBERSHIPS.general_single,
  MEMBERSHIPS.vip_family,
  MEMBERSHIPS.vip_single,
];

export const PAYMENT_METHODS = {
  cash: 'Cash',
  receipt: 'Receipt',
  other: 'Other',
};

export const PAYMENT_METHODS_ENUM = [
  PAYMENT_METHODS.cash,
  PAYMENT_METHODS.receipt,
  PAYMENT_METHODS.other,
];

export const ADD_USER_REQUIRED_FIELDS = [
  'firstName',
  'lastName',
  'nickName',
  'gender',
  'email',
];

export const ADD_PAYMENT_HISTORY_REQUIRED_FIELDS = [
  'membership',
  'amount',
  'paymentDate',
  'paymentMethod',
];

export const HIDDEN_FRONTDESK_PAGES = ['/front-desk'];
