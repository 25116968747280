import axios from 'axios';
const baseURL = process.env.REACT_APP_BACKEND_URL;

export const postCall = async (url, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(baseURL + url, data, {
        headers: {
          platform: 'web',
          tenant_id: localStorage.getItem('tenant_id'),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => {
        reject(err?.response?.data?.error);
      });
  });
};

export const getCall = async (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(baseURL + url, {
        headers: {
          platform: 'web',
          tenant_id: localStorage.getItem('tenant_id'),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => reject(err));
  });
};

export const authorizedPostCall = async (url, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(baseURL + url, data, {
        headers: {
          access_token: 'Bearer ' + localStorage.getItem('access_token'),
          tenant_id: localStorage.getItem('tenant_id'),
          platform: 'web',
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => reject(err));
  });
};

export const authorizedPutCall = async (url, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(baseURL + url, data, {
        headers: {
          access_token: 'Bearer ' + localStorage.getItem('access_token'),
          tenant_id: localStorage.getItem('tenant_id'),
          platform: 'web',
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => reject(err));
  });
};

export const authorizedDeleteCall = async (url) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(baseURL + url, {
        headers: {
          access_token: 'Bearer ' + localStorage.getItem('access_token'),
          tenant_id: localStorage.getItem('tenant_id'),
          platform: 'web',
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => reject(err));
  });
};

export const authorizedGetCall = async (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(baseURL + url, {
        headers: {
          access_token: 'Bearer ' + localStorage.getItem('access_token'),
          tenant_id: localStorage.getItem('tenant_id'),
          platform: 'web',
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((err) => reject(err));
  });
};
