import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
} from 'reactstrap';
import { ReactComponent as CarrotIcon } from './../../assets/carrot-down.svg';

import './Navbars.css';
import AddUserModal from 'components/Modals/AddUser';
import { ROLES } from 'shared/constants';
import { successToast } from 'shared/constants';
import toast from 'react-hot-toast';
import SpinnerLoader from 'components/Misc/Spinner';
import { handleError } from 'services/utilService';
import { updateUser } from 'services/authService';
import ChangePasswordModal from 'components/Modals/ChangePassword';
import { getRole } from 'services/utilService';
import { capitalize } from 'lodash';
import { getProfile } from 'services/authService';
import { changePassword } from 'services/authService';

function AdminNavbar({ theme }) {
  const history = useHistory();
  const [toggleCarrot, setToggleCarrot] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [showSpinner, setSpinner] = useState(true);
  const [user, setUser] = useState();

  const fetchUser = async () => {
    getProfile()
      .then((data) => {
        setSpinner(false);
        setUser(data);
      })
      .catch((error) => {
        setSpinner(false);
        handleError(error);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('tenant_id');
    history.push('/auth/login');
  };

  const rotateCarrotIcon = () => {
    const carrotIcon = document.querySelector('.carrot-icon svg');
    if (!toggleCarrot) {
      carrotIcon.style.transform = 'rotate(180deg)';
    } else {
      carrotIcon.style.transform = 'rotate(0deg)';
    }

    setToggleCarrot(!toggleCarrot);
  };

  const handleAdd = async (values) => {
    try {
      setSpinner(true);
      await updateUser(user?._id, {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
      });
      toast.success('Information updated!', successToast);
      setSpinner(false);
      setAddModal(false);
      fetchUser();
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const getName = () => {
    if (showSpinner) {
      return '';
    }
    if (!user?.firstName && !user?.lastName) {
      return capitalize(
        getRole() === ROLES.frontdesk ? 'Front-Desk' : getRole()
      );
    }
    return (user?.firstName || '') + ' ' + (user?.lastName || '');
  };

  const handleChangePassword = async (values) => {
    try {
      setSpinner(true);
      await changePassword(values?.current, values?.new, values?.confirm);
      toast.success('Password Changed!', successToast);
      setSpinner(false);
      setPasswordModal(false);
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const generateQRCode = () => {
    const url = location.origin + '/#/auth/qr-code';
    window.open(url, '_blank');
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <div className='topbar'>
        <Navbar
          className={classnames(
            'navbar-top navbar-expand-end',
            { 'navbar-dark bg-info-bh-dark': theme === 'dark' },
            { 'navbar-light bg-secondary': theme === 'light' }
          )}
        >
          <div className='mx-5'>
            <UncontrolledDropdown nav>
              <DropdownToggle
                role='button'
                className='nav-link pr-0'
                color=''
                tag='a'
                onClick={(e) => {
                  rotateCarrotIcon();
                }}
              >
                <div className='profile-container'>
                  <div className='profile-picture'>
                    <img src='/user.svg' />
                  </div>
                  <div className='username'>{getName()}</div>
                  <div className='carrot-icon'>
                    <CarrotIcon />
                  </div>
                </div>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <div className='profile-container profile-nested'>
                    <div className='profile-picture'>
                      <img src='/user.svg' />
                    </div>
                    <div className='profile-info-container'>
                      <div className='username'>{getName()}</div>
                      <small>{user?.email}</small>
                      <small>{user?.phone}</small>
                    </div>
                  </div>
                </DropdownItem>
                <DropdownItem onClick={() => setAddModal(true)}>
                  <div className='d-flex align-items-center'>
                    <img src='/user-black.svg' />
                    <span className='account-dropdown-item-name'>
                      Edit Profile
                    </span>
                  </div>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setPasswordModal(true)}>
                  <div className='d-flex align-items-center'>
                    <img src='/lock-black.svg' />
                    <span className='account-dropdown-item-name'>
                      Change Password
                    </span>
                  </div>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={generateQRCode}>
                  <div className='d-flex align-items-center'>
                    <img src='/qr.png' className='small-icon' />
                    <span className='account-dropdown-item-name'>
                      Generate QR Code
                    </span>
                  </div>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={handleLogout}>
                  <div className='d-flex align-items-center'>
                    <img src='/logout-black.svg' />
                    <span className='account-dropdown-item-name'>Logout</span>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Navbar>
      </div>
      {addModal && (
        <AddUserModal
          open={addModal}
          handleClose={() => setAddModal(false)}
          role={ROLES.admin}
          handleAdd={handleAdd}
          editObj={user}
        />
      )}
      {passwordModal && (
        <ChangePasswordModal
          open={passwordModal}
          handleClose={() => setPasswordModal(false)}
          handleSubmit={handleChangePassword}
        />
      )}
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
};

export default AdminNavbar;
