import {
  authorizedGetCall,
  authorizedPostCall,
  authorizedDeleteCall,
} from './APIsService';

export const getPendingRequests = async (body) => {
  return new Promise((resolve, reject) => {
    authorizedGetCall(
      `/user/pending_requests/all?page=${body?.pageNo || ''}&limit=${
        body?.size || ''
      }&status=${body?.status || ''}&role=${body?.role || ''}&search=${
        body?.search || ''
      }`
    )
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const acceptOrRejectRequest = async (userId, body) => {
  return new Promise((resolve, reject) => {
    authorizedPostCall(`/user/pending_requests/accept_reject/${userId}`, body)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const removeInvitationRequest = async (userId) => {
  return new Promise((resolve, reject) => {
    authorizedDeleteCall(`/user/pending_requests/delete_request/${userId}`)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
