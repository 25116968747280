import React, { useCallback, useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Input,
} from 'reactstrap';
import { fetchAll } from 'services/attendanceService';
import SpinnerLoader from 'components/Misc/Spinner';
import DatePickerComp from 'components/DateRangePicker/DatePicker';
import _debounce from 'lodash/debounce';
import moment from 'moment';
import { getDateFormat } from 'services/utilService';
import { getTimeFormat } from 'services/utilService';
import { checkoutTime } from 'services/utilService';
import { getDuration } from 'services/utilService';
import { checkin } from 'services/attendanceService';
import { checkout } from 'services/attendanceService';
import toast from 'react-hot-toast';
import UpdateModal from 'components/Modals/UpdateTime';
import { update } from 'services/attendanceService';
import Papa from 'papaparse';
import { downloadData } from 'services/utilService';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { MultiDropdown } from 'components/Misc/MultiDropdown';
import { roleFilter } from 'shared/constants';
import { ROLES } from 'shared/constants';
import { handleError } from 'services/utilService';
import { successToast } from 'shared/constants';
import { sumDurations } from 'services/utilService';

function Attendance() {
  const history = useHistory();

  const pageSize = 10;
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showSpinner, setSpinner] = useState(true);
  const [date, setDate] = useState(new Date());
  const [showModal, setModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [role, setRole] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [initialCheckin, setInitialCheckin] = useState();
  const [initialCheckout, setInitialCheckout] = useState();
  const [entryId, setEntryId] = useState();
  const [shownRow, setShownRow] = useState();

  React.useEffect(() => {
    fetchUsers({ date });
  }, [date]);

  const fetchUsers = async (body = {}) => {
    body.onlyAttendance = true;
    setSpinner(true);
    fetchAll(body)
      .then((data) => {
        setUsers(data.data);
        setCount(data.meta.total_count);
        setSpinner(false);
      })
      .catch((error) => {
        setSpinner(false);
        handleError(error);
      });
  };

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
      pagesArr.push(
        <PaginationItem className={currentPage === i ? 'active' : ''}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchUsers({
      size: pageSize,
      pageNo: pageNumber,
      ...(search.trim() && { search: search.trim() }),
      date,
    });
    setPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debounceFn(e.target.value);
  };

  function debounceSearch(val, roleVal = role) {
    fetchUsers({
      size: pageSize,
      pageNo: 1,
      ...(val.trim() && { search: val.trim() }),
      date,
      ...(roleVal && roleVal.length && { role: roleVal }),
    });
  }

  const debounceFn = useCallback(_debounce(debounceSearch, 500), []);

  const changeRole = (value) => {
    setRole(value);
    debounceFn(search, value);
  };

  const handleCheckin = async (userId, role) => {
    try {
      setSpinner(true);
      await checkin({ userId, date: date.toISOString(), role });
      fetchUsers({
        size: pageSize,
        pageNo: currentPage,
        ...(search.trim() && { search: search.trim() }),
        date,
      });
      toast.success('User Check In Updated!', successToast);
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const handleCheckout = async (userId, role) => {
    try {
      setSpinner(true);
      await checkout({ userId, date: date.toISOString(), role });
      fetchUsers({
        size: pageSize,
        pageNo: currentPage,
        ...(search.trim() && { search: search.trim() }),
        date,
      });
      toast.success('User Check Out Updated!', successToast);
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const updateTimeHandler = (userId, role, attendance) => {
    console.log('update time handler', { userId, role, attendance });
    setSelectedUser(userId);
    setInitialCheckin(attendance?.checkIn ? new Date(attendance?.checkIn) : '');
    setInitialCheckout(
      attendance?.checkOut ? new Date(attendance?.checkIn) : ''
    );
    setEntryId(attendance?._id);
    setSelectedRole(role);
    setModal(true);
  };

  const handleUpdate = async (times) => {
    try {
      setSpinner(true);
      setModal(false);
      const checkIn = moment(date).toDate();
      const selectedCheckin = moment(times.checkin).toDate();
      checkIn.setHours(selectedCheckin.getHours());
      checkIn.setMinutes(selectedCheckin.getMinutes());
      checkIn.setSeconds(0);
      checkIn.setMilliseconds(0);

      const checkOut = moment(date).toDate();
      const selectedCheckout = moment(times.checkout).toDate();
      checkOut.setHours(selectedCheckout.getHours());
      checkOut.setMinutes(selectedCheckout.getMinutes());
      checkOut.setSeconds(0);
      checkOut.setMilliseconds(0);

      await update(
        { userId: selectedUser, date: date.toISOString(), role: selectedRole },
        {
          checkIn: checkIn.toISOString(),
          checkOut: checkOut.toISOString(),
          entryId,
        }
      );
      fetchUsers({
        size: pageSize,
        pageNo: currentPage,
        ...(search.trim() && { search: search.trim() }),
        date,
      });
      toast.success('Time updated!', successToast);
    } catch (error) {
      setSpinner(false);
      handleError(error);
    }
  };

  const exportData = async () => {
    setSpinner(true);
    let data = await fetchAll({
      fetchAll: true,
    });
    data = data.data.map((user) => {
      return {
        NAME: (user?.firstName || '') + ' ' + (user?.lastName || ''),
        EMAIL: user?.email,
        DATE: getDateFormat(date),
        'CHECK IN': getTimeFormat(user.attendance[0]?.checkIn),
        'CHECK OUT': checkoutTime(
          user.attendance[0]?.checkIn,
          user.attendance[0]?.checkOut,
          date
        ),
        'USER TYPE': _.capitalize(user?.role),
        DURATION: getDuration(
          user.attendance[0]?.checkIn,
          user.attendance[0]?.checkOut,
          date
        ).formatted,
      };
    });
    const csvData = Papa.unparse(data);
    downloadData(
      csvData,
      `${moment(date).format('DD MMM, yyyy')}.csv`,
      'text/csv;charset=utf-8;'
    );
    setSpinner(false);
  };

  const checkInDropdown = (
    userId,
    isHybrid,
    defaultRole,
    hasAttendance,
    attendance,
    attendanceRole
  ) => {
    console.log({ attendance, attendanceRole });
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className='btn-icon-only text-light action-bg'
          color=''
          role='button'
          size='sm'
        >
          <img src='/action.svg' />
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-arrow' right>
          {attendance?.at(-1)?.checkIn && !attendance?.at(-1)?.checkOut ? (
            <DropdownItem
              onClick={() =>
                handleCheckout(userId, attendanceRole || defaultRole)
              }
            >
              <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                <div>Check Out</div>
              </div>
            </DropdownItem>
          ) : (
            <>
              <DropdownItem
                onClick={() =>
                  handleCheckin(userId, attendanceRole || defaultRole)
                }
              >
                <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                  <div>Check In</div>
                </div>
              </DropdownItem>
            </>
          )}
          {attendance?.length === 1 &&
          attendance[0]?.checkIn &&
          attendance[0]?.checkOut ? (
            <DropdownItem
              onClick={(e) =>
                updateTimeHandler(
                  userId,
                  attendanceRole || defaultRole,
                  attendance[0]
                )
              }
            >
              <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                <div>Update Time</div>
              </div>
            </DropdownItem>
          ) : (
            ''
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const checkInSubMenuDropdown = (userId, role, attendance) => {
    console.log('checc', attendance, role);
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className='btn-icon-only text-light action-bg'
          color=''
          role='button'
          size='sm'
        >
          <img src='/action.svg' />
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-arrow' right>
          {attendance?.checkIn && !attendance?.checkOut && (
            <DropdownItem onClick={() => handleCheckout(userId, role)}>
              <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                <div>Check Out</div>
              </div>
            </DropdownItem>
          )}

          <DropdownItem
            onClick={(e) => updateTimeHandler(userId, role, attendance)}
          >
            <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
              <div>Update Time</div>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const getActionDropdown = (user, role) => (
    <UncontrolledDropdown>
      <DropdownToggle
        className='btn-icon-only text-light action-bg'
        color=''
        role='button'
        size='sm'
      >
        <img src='/action.svg' />
      </DropdownToggle>
      <DropdownMenu className='dropdown-menu-arrow' right>
        {getAttendanceByRole(user.attendance, role)?.checkIn &&
          !getAttendanceByRole(user.attendance, role)?.checkOut &&
          moment(date).isSame(moment(), 'day') && (
            <>
              <DropdownItem onClick={(e) => handleCheckout(e, user, role)}>
                <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
                  <div>Check Out</div>
                </div>
              </DropdownItem>
              <DropdownItem divider />
            </>
          )}
        <DropdownItem onClick={(e) => updateTimeHandler(e, user, role)}>
          <div className='d-flex align-items-center justify-content-start dropdown-menu-item'>
            <div>Update Time</div>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const getAttendanceByRole = (attendance, role) => {
    return attendance.find((at) => at.role === role);
  };

  console.log('users->', users);

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <Container className='mt-3' fluid>
        <div className='mb-3 d-flex align-items-center justify-content-end'>
          <div className='search-input mr-3'>
            <div className='search-icon'>
              <img src='/search-icon.svg' />
            </div>
            <Input
              className='searchBox'
              placeholder='Search'
              type='text'
              value={search}
              onChange={handleSearch}
            />
          </div>
          <div className='date-picker'>
            <DatePickerComp
              date={date}
              setDate={setDate}
              maxDate={moment().toDate()}
            />
          </div>
        </div>
        <Row>
          <div className='col'>
            <Card>
              <CardHeader className='border-0'>
                <div className='table-header'>
                  <div className='header-left'>
                    <div className='table-title'>Check In/Out</div>
                  </div>
                  <div className='header-right'>
                    <div className='export-container mr-4'>
                      <div
                        className='export'
                        onClick={exportData}
                        role='button'
                      >
                        Export
                      </div>
                    </div>
                    <div>
                      <MultiDropdown
                        placeholder={'User Type'}
                        value={role}
                        options={roleFilter}
                        valueChange={changeRole}
                      />
                    </div>
                  </div>
                </div>
              </CardHeader>
              <div className='table-responsive'>
                <Table className='dataTable align-items-center'>
                  <thead className='thead-bh icon-color-light'>
                    <tr>
                      <th className='w-20 name-field' scope='col'>
                        NAME
                      </th>
                      <th className='w-15' scope='col'>
                        DATE
                      </th>
                      <th className='w-15' scope='col'>
                        CHECK IN
                      </th>
                      <th className='w-15' scope='col'>
                        CHECK OUT
                      </th>
                      <th className='w-10' scope='col'>
                        USER TYPE
                      </th>
                      <th className='w-15' scope='col'>
                        DURATION
                      </th>
                      <th className='w-5' scope='col'></th>

                      {/* <th className='w-5' scope='col'></th> */}
                      <th className='w-5' scope='col'></th>
                    </tr>
                  </thead>
                  <tbody className='list'>
                    {users.length ? (
                      users.map((user, index) => (
                        <>
                          <tr key={index}>
                            <td className='overflowStyle text-capitalize pl-0'>
                              <div className='user-info-cell'>
                                <div className='user-image'>
                                  <img src='user-image.png' />
                                </div>
                                <div
                                  className='username overflowStyle'
                                  role='button'
                                  onClick={() =>
                                    history.push(`/admin/user/${user._id}`)
                                  }
                                >
                                  {(user?.firstName || '') +
                                    ' ' +
                                    (user?.lastName || '')}
                                </div>
                              </div>
                            </td>
                            <td className='overflowStyle text-capitalize pl-0 pr-0'>
                              {getDateFormat(date)}
                            </td>
                            <td className='overflowStyle text-capitalize pl-0 pr-0'>
                              {user?.attendance
                                ? getTimeFormat(
                                    user?.attendance?.checkinCheckouts?.at(0)
                                      ?.checkIn
                                  )
                                : '-'}
                            </td>
                            <td className='overflowStyle pl-0 pr-0'>
                              {user?.attendance
                                ? getTimeFormat(
                                    user?.attendance?.checkinCheckouts?.at(-1)
                                      ?.checkOut
                                  )
                                : '-'}
                            </td>

                            <td className='overflowStyle pl-0 pr-0'>
                              {!user?.hybridRole
                                ? _.capitalize(user?.role)
                                : _.capitalize(user?.attendance?.role)}
                            </td>
                            <td className='overflowStyle pl-0 pr-0'>
                              {sumDurations(
                                user?.attendance?.checkinCheckouts,
                                date
                              )}
                            </td>

                            <td
                              className='actionDropdown px-0'
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              {user?.attendance?.checkinCheckouts?.length >
                              1 ? (
                                <img
                                  className='xsmall-icon'
                                  src='/chevron-down.svg'
                                  alt='chevron'
                                  role='button'
                                  onClick={() =>
                                    setShownRow((prev) =>
                                      prev > -1 && prev === index ? -1 : index
                                    )
                                  }
                                />
                              ) : (
                                ''
                              )}
                            </td>

                            <td className='actionDropdown px-0'>
                              {!user?.attendance?.checkinCheckouts?.length ||
                              user?.attendance?.checkinCheckouts?.length ===
                                1 ||
                              (user?.attendance?.checkinCheckouts?.at(-1)
                                ?.checkIn &&
                                user?.attendance?.checkinCheckouts?.at(-1)
                                  ?.checkOut)
                                ? checkInDropdown(
                                    user?._id,
                                    user?.hybridRole,
                                    user?.role,
                                    user?.hasAttendance,
                                    user?.attendance?.checkinCheckouts,
                                    user?.attendance?.role
                                  )
                                : ''}
                            </td>
                          </tr>
                          {shownRow === index && (
                            <>
                              {user?.attendance?.checkinCheckouts?.map(
                                (attendance) => (
                                  <tr>
                                    <td></td>
                                    <td></td>

                                    <td>
                                      {getTimeFormat(attendance?.checkIn)}
                                    </td>
                                    <td>
                                      {checkoutTime(
                                        attendance?.checkIn,
                                        attendance?.checkOut,
                                        date
                                      )}
                                    </td>
                                    <td></td>
                                    <td>
                                      {
                                        getDuration(
                                          attendance?.checkIn,
                                          attendance?.checkOut,
                                          date
                                        ).formatted
                                      }
                                    </td>
                                    <td></td>

                                    <td className='pl-1 pr-0'>
                                      {checkInSubMenuDropdown(
                                        user?._id,
                                        user?.attendance?.role,
                                        attendance
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </>
                          )}
                        </>
                      ))
                    ) : (
                      <></>
                    )}
                    <tr></tr>
                  </tbody>
                </Table>
                {!showSpinner && !users.length && (
                  <div className='d-flex align-items-center justify-content-center mb-3'>
                    No records found
                  </div>
                )}
              </div>
              <CardFooter className='py-4 custom-footer'>
                <nav
                  className='d-flex align-items-center justify-content-end'
                  aria-label='...'
                >
                  <Pagination
                    className='pagination justify-content-end mb-0'
                    listClassName='justify-content-end mb-0'
                  >
                    <PaginationItem
                      className={currentPage === 1 ? 'disabled' : ''}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage - 1)}
                        tabIndex='-1'
                      >
                        <i className='fas fa-angle-left icon-color-light' />
                        <span className='sr-only'>Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {pages().map((page, index) => (
                      <div key={index}>{page}</div>
                    ))}
                    <PaginationItem
                      className={
                        currentPage >= Math.ceil(count / pageSize)
                          ? 'disabled'
                          : ''
                      }
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, currentPage + 1)}
                      >
                        <i className='fas fa-angle-right icon-color-light' />
                        <span className='sr-only'>Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {showModal && (
        <UpdateModal
          open={showModal}
          handleClose={() => setModal(false)}
          handleUpdate={handleUpdate}
          initialCheckin={initialCheckin}
          initialCheckout={initialCheckout}
        />
      )}
    </>
  );
}

export default Attendance;
